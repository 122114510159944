import React from 'react';
import { Text, Link } from '@dropbox/dig-components/dist/typography';
import styles from './FooterDisclosure.module.scss';

export const FooterDisclosure = (): JSX.Element => (
  <div className={styles['footer-row-content']}>
    <Text className={styles['footer-text']}>
      Formswift is not a law firm and does not provide legal advice or
      representation. Our documents are not a substitute for the advice of an
      attorney. Communications between you and Formswift are governed by our{' '}
      <Link className={styles['footer-link']} href="/privacy">
        Privacy Policy
      </Link>{' '}
      but are not protected by the attorney-client privilege or as work product.
      We do not provide advice, opinions, or recommendations about
      individual&apos;s legal rights, options, strategies, or the selection of
      forms. Your use of this website and our forms is governed by our{' '}
      <Link className={styles['footer-link']} href="/terms">
        Terms of Service
      </Link>
      .
    </Text>
  </div>
);
